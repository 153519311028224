import React, { useState, useEffect } from 'react';
import '../App.css'; // Import your CSS file for styling
import axios from 'axios';
import { MediaUpload } from '../components/MediaUpload';
import logo from '../assets/logo.png';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Loading } from '../components/Loading';
import { GlobalData } from '../Context';
import { useContext } from 'react';
import jStat from 'jstat';

// import { Loading } from './Loading';

function Home() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [extractedText, setExtractedText] = useState(null);
  const [manualData, setManualData] = useState();
  const [QuoteName, setQuoteName] = useState('');
  const [AvgNet, setAvgNet] = useState();
  const [startYear, setStartYear] = useState();
  const [EndYear, setEndyear] = useState();
  const [ErrorMsg, setErrorMsg] = useState('');
  const [QuoteError, setQuoteError] = useState('');
  const { QuoteSetter, ImageSetter, ImageSetterWOF } =
    useContext(GlobalData);
  // var math = require('mathjs');
  const [data, setData] = useState({
    2022: '',
    2021: '',
    2020: '',
    2019: '',
    2018: '',
    2017: '',
    2016: '',
    2015: '',
    2014: '',
    2013: '',
    2012: '',
    2011: '',
    2010: '',
    2009: '',
    2008: '',
    2007: '',
    2006: '',
    2005: '',
    2004: '',
    2003: '',
  });
  const navigate = useNavigate();
  const CheckValidation = () => {
    if (QuoteName == '') {
      setQuoteError('Quote for Field is required ');
      toast.error('Quote for Field is required', {
        autoClose: 2000,
        hideProgressBar: true,
      });
      return false;
    } else if (!selectedFile) {
      setErrorMsg('kindly Upload Pdf ');
      return false;
    }
    return true;
  };
  

  const generateInputFields = () => {
    // Ensure startYear and endYear are valid numbers
    if (!startYear || !EndYear || startYear > EndYear) {
      toast.error('Start Year must be lesser than End year');
      return;
    }

    // Create a new data object with years as keys
    const newData = {};
    for (let year = startYear; year <= EndYear; year++) {
      newData[year] = '';
    }

    setData(newData);
  };

  const CheckValidation2 = () => {
    if (QuoteName == '') {
      setQuoteError('Quote for Field is required ');
      toast.error('Quote for Field is required', {
        autoClose: 2000,
        hideProgressBar: true,
      });
      return false;
    }
    return true;
  };

  useEffect(() => {
    ImageSetter('');
    ImageSetterWOF('');
    if (document.body.classList.contains('RemoveScroll')) {
      document.body.classList.remove('RemoveScroll');
    }
  }, []);

  const handleUpload = async (e) => {
    e.preventDefault();
    if (CheckValidation() == true) {
      QuoteSetter(QuoteName);
      navigate('/pdfpage/auto', {
        state: {
          file: selectedFile,
        },
      });
    }
  };

  console.log(extractedText, '...');

  const handleInputChange = (year, newValue) => {
    if (newValue.length > 10) {
      newValue.slice(0, 10);
    } else {
      setData((prevData) => ({
        ...prevData,
        [year]: newValue,
      }));
    }
  };

  const handleFile = (files) => {
    setSelectedFile(files[0]);
    setErrorMsg('');
  };

  console.log(data);

  const GeneratePdf = (e) => {
    e.preventDefault();
    const ManualData = formulaApply();

    if (CheckValidation2() == true) {
      QuoteSetter(QuoteName);
      navigate('/pdfpage/manual', {
        state: {
          ManualData: ManualData,
        },
      });
    }
  };

  const formulaApply = () => {
    // Constants
    const amounts = Object.values(data).map(Number); // Assuming data is already defined
    const years = Object.keys(data).map(Number);
    console.log(amounts, 'amounts');

    // Mean amount
    const amountMean =
      amounts.reduce((acc, val) => acc + val, 0) / amounts.length;

    // Standard Deviation of Amount
    const sdAmount = Math.sqrt(
      amounts.reduce(
        (acc, val) => acc + Math.pow(val - amountMean, 2),
        0
      ) /
        (amounts.length - 1)
    );

    // T State
    const tStateAmount = (0 - amountMean) / sdAmount;

    //POL

    function TDIST(x, deg_freedom, tails) {
    
      // Truncate degrees of freedom and tails to integers
      deg_freedom = Math.floor(deg_freedom);
      tails = Math.floor(tails);
      
      // Calculate the t-distribution based on the tails argument
      if (tails === 1) {
        // One-tailed distribution
        return 1 - jStat.studentt.cdf(x, deg_freedom);
      } else {
        // Two-tailed distribution
        return 2 * (1 - jStat.studentt.cdf(x, deg_freedom));
      }
    }
    
    
    
    const POL = parseFloat(
      TDIST(Math.abs(tStateAmount), amounts.length, 1)
    ).toFixed(3);

    // Data on excel sheet
    const excelData = [
      {
        risk: 'High',
        Producer: 0.3,
        RainShield: 0.7,
        'Lower Limit': 0.287,
        'Upper Limit': 1.0,
      },
      {
        risk: 'Medium High',
        Producer: 0.4,
        RainShield: 0.6,
        'Lower Limit': 0.234,
        'Upper Limit': 0.2869,
      },
      {
        risk: 'Medium',
        Producer: 0.5,
        RainShield: 0.5,
        'Lower Limit': 0.129,
        'Upper Limit': 0.2339,
      },
      {
        risk: 'Medium Low',
        Producer: 0.6,
        RainShield: 0.4,
        'Lower Limit': 0.093,
        'Upper Limit': 0.1289,
      },
      {
        risk: 'Low',
        Producer: 0.7,
        RainShield: 0.3,
        'Lower Limit': 0.0,
        'Upper Limit': 0.0929,
      },
    ];

    let producer = 0;
    let risk = '';
    let RainShield = '';

    for (const row of excelData) {
      if (row.Producer === 0.3) {
        if (POL > row['Lower Limit'] && POL < row['Upper Limit']) {
          producer = row.Producer;
          risk = row.risk;
          RainShield = row.RainShield;
        }
      } else {
        if (POL > row['Lower Limit'] && POL <= row['Upper Limit']) {
          producer = row.Producer;
          risk = row.risk;
          RainShield = row.RainShield;
        }
      }
    }

    // Amounts with formulas
    const amountsWithFormulas = amounts.map((value) =>
      Math.round(Math.max(value, 0) * producer)
    );

    // Create the object (data without formulas)
    const result_without_formulas = {};
    for (let i = 0; i < years.length; i++) {
      result_without_formulas[years[i]] = amounts[i];
    }

    // Create the object (Data with Formulas)
    const result_with_formulas = {};
    for (let i = 0; i < years.length; i++) {
      result_with_formulas[years[i]] = amountsWithFormulas[i];
    }

    console.log({
      Data_without_formulas: result_without_formulas,
      Data_with_formulas: result_with_formulas,
      Extra_Data: {
        risk: risk,
        producer: producer,
        RainShield: RainShield,
        avg_net_value: AvgNet,
        Pol: POL,
        tStateAmount: tStateAmount,
        amountMean: amountMean,
        sdAmount: sdAmount,
      },
    })
    return {
      Data_without_formulas: result_without_formulas,
      Data_with_formulas: result_with_formulas,
      Extra_Data: {
        risk: risk,
        producer: producer,
        RainShield: RainShield,
        avg_net_value: AvgNet,
        Pol: POL,
        tStateAmount: tStateAmount,
        amountMean: amountMean,
        sdAmount: sdAmount,
      },
    };
  };

  function generateYear() {
    const currentYear = new Date().getFullYear(); // Get the current year
    const startYear = 2000;
    const yearsArray = [];

    for (let year = startYear; year < currentYear; year++) {
      yearsArray.push(year);
    }

    return yearsArray;
  }

  const handleAvgNetChange = (e) => {
    const inputValue = e.target.value;

    // Check if the input is empty (e.g., after pressing backspace)
    setAvgNet(inputValue);
    if (/^[+-]?(?![e.])(\d+(\.\d*)?|\.\d+)$/.test(inputValue)) {
    }
  };

  return (
    <div className="App h-screen">
      <div className="main py-12 px-4 md:px-16">
        <ToastContainer />
        <header className="mb-8">
          <img src={logo} alt="Pdf Icon" className="m-auto md:m-0" />
        </header>
        <div className="w-full flex justify-center mb-8">
          <div className="flex flex-col justify-center gap-1">
            <div>
              <p className="text-md whitespace-nowrap w-24 pb-2  font-semibold">
                Quote for:
              </p>
              <input
                className={`h-6 lg:w-96 md:w-80 sm:w-full flex-1 rounded-sm outline-none py-4 px-2 border border-black border-opacity-50 placeholder-slate-300 ${
                  QuoteError != '' ? 'border-1 border-red-700' : null
                }`}
                type="text"
                placeholder="Quote for"
                value={QuoteName}
                onChange={(e) => {
                  setQuoteName(e.target.value);
                  setQuoteError('');
                }}
              />
            </div>
            <div>
              {QuoteError != '' && (
                <p className="text-red-700 text-sm  font-semibold">
                  {QuoteError}
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 xl:grid-cols-1    gap-12 md:gap-32 items-start w-10/12 m-auto">
          {/* Auto */}
          <div className="grid grid-cols-1 xl:grid-cols-2 gap-8">
            <div>
              <form
                onSubmit={(e) => handleUpload(e)}
                className="flex flex-col items-center justify-center xl:w-full lg:w-full md:w-full sm:w-full]"
              >
                <div className="w-full">
                  <h1 className="font-bold text-2xl pb-4 text-center">
                    Auto Entry
                  </h1>
                </div>

                <MediaUpload handleFile={handleFile} />
                {/* Error msg */}
                <div>
                  {ErrorMsg != '' ? (
                    <p className="mt-4 -mb-2 text-red-700 font-semibold">
                      {ErrorMsg}
                    </p>
                  ) : null}
                </div>
                <div className="text-center">
                  <button
                    type="submit"
                    className="btn text-center font-semibold uppercase mt-4 transition"
                  >
                    Submit for Quote
                  </button>
                </div>
              </form>
            </div>
            <div>
              <div>
                <h1 className="font-bold text-2xl pb-4 text-center">
                  Manual Entry
                </h1>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    generateInputFields();
                  }}
                >
                  <div className="flex xl:flex-row lg:flex-row md:flex-row sm:flex-row flex-col   items-center mb-2">
                    <p className="text-md  pr-2 whitespace-nowrap text-right ">
                      Start Year:
                    </p>
                    <select
                      name=""
                      id=""
                      value={startYear}
                      required
                      className="h-8 text-sm xl:w-full lg:w-full md:w-full sm:w-full w-52  mr-2 rounded-sm cursor-pointer outline-none  px-2 border border-black border-opacity-50 "
                      onChange={(e) => setStartYear(e.target.value)}
                    >
                      <option value="">Select Year</option>

                      {generateYear().map((val) => (
                        <option key={val} value={val}>
                          {val}
                        </option>
                      ))}
                    </select>

                    {/* <input
                  required
                  className="h-6 w-full mr-2 rounded-sm outline-none py-4 px-2 border border-black border-opacity-50 placeholder-slate-300"
                  type="number"
                  placeholder="Start Year "
                  value={startYear}
                  onChange={(e) => setStartYear(e.target.value)}
                /> */}
                    <p className="text-md  pr-2 whitespace-nowrap text-right ">
                      End Year:
                    </p>
                    {/* <input
                  required
                  className="h-6 w-full rounded-sm outline-none py-4 px-2 border border-black border-opacity-50 placeholder-slate-300"
                  type="number"
                  placeholder="End Year"
                  value={EndYear}
                  onChange={(e) => setEndyear(e.target.value)}
                /> */}
                    <select
                      name=""
                      id=""
                      required
                      defaultValue={'Select'}
                      value={EndYear}
                      className="h-8 text-sm xl:w-full lg:w-full md:w-full sm:w-full w-52 mr-2 rounded-sm cursor-pointer outline-none px-2 border border-black border-opacity-50 placeholder-slate-300"
                      onChange={(e) => setEndyear(e.target.value)}
                    >
                      <option value="">Select Year</option>

                      {generateYear()
                        .reverse()
                        .map((val) => (
                          <option value={val}>{val}</option>
                        ))}
                    </select>
                  </div>
                  <div className="text-center pt-2 pb-4">
                    <button
                      type="Submit"
                      className="btn text-center font-semibold  uppercase"
                    >
                      Generate Input Fields
                    </button>
                  </div>
                </form>
                <form onSubmit={(e) => GeneratePdf(e)}>
                  <div className="grid gap-y-2">
                    <div className="flex flex-row items-center">
                      <p className="text-md mr-1 pr-2 whitespace-nowrap text-right ">
                        Avg Net:
                      </p>
                      <input
                        required
                        className="h-6 text-sm w-full rounded-sm outline-none py-4 px-2 border border-black border-opacity-50 placeholder-slate-300"
                        type="number"
                        placeholder="Avg Net Per Year "
                        value={AvgNet}
                        onKeyDown={(evt) =>
                          ['e', 'E', '.', '+'].includes(evt.key) &&
                          evt.preventDefault()
                        }
                        onChange={(e) => handleAvgNetChange(e)}
                      />
                    </div>
                    {Object.entries(data)
                      .reverse()
                      .map(([year, value]) => {
                        return (
                          <div key={year}>
                            <div className="flex flex-row items-center">
                              <p className="text-md  pr-2 whitespace-nowrap w-24 text-right ">
                                {year}:
                              </p>
                              <input
                                required
                                className="h-6 w-full text-sm rounded-sm outline-none py-4 px-2 border border-black border-opacity-50 placeholder-slate-300"
                                type="number"
                                placeholder="Enter amount here"
                                value={value}
                                onKeyDown={(evt) =>
                                  ['e', 'E', '.', '+'].includes(
                                    evt.key
                                  ) && evt.preventDefault()
                                }
                                onChange={(e) =>
                                  handleInputChange(
                                    year,
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </div>
                        );
                      })}
                  </div>

                  <div className="text-center pt-6">
                    <button
                      type="Submit"
                      className="btn text-center font-semibold  uppercase"
                    >
                      Submit for quote
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          {/* Manual */}
        </div>
        {/* Chart 1 */}
        {/* <div className='footer pt-8'>
          <div className="grid grid-cols-2">
            <div id='canvas'>
              <canvas id="chart" className='hidden'></canvas>
            </div>
          </div>      
        </div> */}
      </div>
    </div>
  );
}

export default Home;
