import { useRef, useState } from "react";
import pdfIcon from '../assets/pdf-icon.png'
import '../pdf.css'

// drag drop file component
export const MediaUpload = ({handleFile}) => {
  // drag state
  const [dragActive, setDragActive] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  
  // ref
  const inputRef = useRef(null);
  
  // handle drag events
  const handleDrag = function(e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };
  
  // triggers when file is dropped
  const handleDrop = function(e) {
    e.preventDefault();
    e.stopPropagation();
    
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFile(e.dataTransfer.files);
      setSubmitted(true);
    }
  };
  
  // triggers when file is selected with click
  const handleChange = function(e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFile(e.target.files);
      setSubmitted(true);
    }
  };
  
// triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current.click();
  };
  
  return (
    <>
        <form id="form-file-upload" onDragEnter={handleDrag} onSubmit={() => handleDrop()}>
          <input ref={inputRef} type="file" accept=".pdf" id="input-file-upload" onChange={handleChange} />
          <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? "drag-active cursor-pointer" : "cursor-pointer" }>
            <div className="cursor-pointer">
              <img src={pdfIcon} alt="Pdf Icon" className="m-auto" />
              <div className="pt-8">
                <p className="font-bold text-lg">Drag and drop PDF here</p>
                <p className="font-medium text-lg">or</p>
                <p className="font-bold text-xl underline" >Upload a PDF</p>
              </div>
            </div> 
          </label>
          { dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div> }
        </form>
    {
      submitted?(
        <div className="w-full pt-6 text-center">
          <div className="font-bold">Successfully Uploaded</div>
        </div>
        ): ('')
    }
    </>
  );
};