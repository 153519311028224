import React, { useEffect, useRef, useState } from 'react';
import Plotly from 'plotly.js-dist';
import createPlotlyComponent from 'react-plotly.js/factory';
import html2canvas from 'html2canvas';
import { GlobalData } from '../Context';
import { useContext } from 'react';
import '../../src/Chart.css';

const Plot = createPlotlyComponent(Plotly);

const BarChart = ({ ExtractData, ExtractDataWOF }) => {
  const { ImageSetter, ImageSetterWOF } = useContext(GlobalData);
  const chartContainerRef = useRef(null);
  const [capturedImage, setCapturedImage] = useState(null);
  useEffect(() => {
    var years = [];
    var amounts = [];
    if (ExtractData) {
      years = Object.keys(ExtractData);
      amounts = Object.values(ExtractData);
    } else if (ExtractDataWOF) {
      years = Object.keys(ExtractDataWOF);
      amounts = Object.values(ExtractDataWOF);
    }

    // Calculate the minimum and maximum values in the 'amounts' array
    const minValue = Math.min(...amounts);
    const maxValue = Math.max(...amounts);
    const averageValue = (minValue + maxValue) / 2;

    // Calculate the x values for the bars so they start from the left edge

    function formatUSNumber(number) {
      // Convert the number to a string and split it into integer and decimal parts
      const parts = number.toString().split('.');
      const integerPart = parts[0];
      const decimalPart = parts.length > 1 ? `.${parts[1]}` : '';

      // Use regular expressions to add commas as thousands separators to the integer part
      const formattedIntegerPart = integerPart.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ','
      );

      // Combine the integer and decimal parts with the formatted integer part
      const formattedNumber = formattedIntegerPart + decimalPart;

      return formattedNumber;
    }
    const data = [
      {
        x: amounts,
        y: years,
        type: 'bar',
        orientation: 'h',
        marker: {
          color: amounts.map((amount) =>
            amount < 0 ? '#EC5D5F' : '#5DBF82'
          ),
        },
        text: amounts.map((amount) => `$${formatUSNumber(amount)}`),
        textposition: 'outside', // Move the text to the right side
        textfont: {
          size: 44,
          color: '#000000',
          family: 'Arial',
          weight: 'bold',
        }, // Customize the text appearance
        hoverinfo: 'none',
      },
    ];

    const yearsWithMargin = years.map((year) => `${year}  `);

    const layout = {
      xaxis: {
        range: [
          minValue >= 0 ? 0 : minValue - ((maxValue*80)/100),
          maxValue + ((maxValue*80)/100),
        ],
        showgrid: true,
        zeroline: true,
        tickfont: {
          size: 42, // Set the font size for y-axis tick labels
          color: '#000000',
          family: 'Arial',
          weight: 'bold', // Make the y-axis tick labels bold
        },
        font: {
          family: 'Times New Roman',
          size: 25,
          weight: 'bold',
        },
      },
      yaxis: {
        title: '',
        showticklabels: true,
        tickvals: years,
        zeroline: true,
        ticktext: yearsWithMargin,
        tickfont: {
          size: 45, // Set the font size for y-axis tick labels
          color: '#000000',
          family: 'Arial',
          weight: 'bold', // Make the y-axis tick labels bold
        },
        font: {
          family: 'Times New Roman',
          size: 27,
          weight: 'bold',
        },
      },
      margin: {
        t: 40, // Remove top margin padding
        l: 150, // Add padding to the left side
        r: 150, // Add padding to the right side
      },
      

      showlegend: false,
      
    };

    // Check if chartContainerRef is defined and not null
    if (chartContainerRef.current) {
      Plotly.newPlot(chartContainerRef.current, data, layout).then(
        () => {
          // Capture the chart as an image when the chart is fully rendered
          html2canvas(chartContainerRef.current).then((canvas) => {
            const image = canvas.toDataURL('image/png');
            setCapturedImage(image);
            if (ExtractData) ImageSetter(image);
            else if (ExtractDataWOF) {
              ImageSetterWOF(image);
            }
          });
        }
      );
    }
  }, [ExtractData]);

  return <div style={{ width: '1500px', height: '1400px' }} className='w-[1500px] h-[1400px]' ref={chartContainerRef}></div>;
};

export default BarChart;
