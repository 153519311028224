import { createContext, useState } from 'react';

export const GlobalData = createContext(null);

function Context({ children }) {
  const [images, setImages] = useState();
  const [imagesWOF, setImagesWOF] = useState();
  const [Quote, setQuote] = useState("");

  const ImageSetter = (val) => {
    setImages(val);
  };

  const ImageSetterWOF = (val) => {
    setImagesWOF(val);
  };
  const QuoteSetter=(val)=>{
setQuote(val)
  }

  return (
    <GlobalData.Provider value={{ images, ImageSetter ,ImageSetterWOF,imagesWOF,QuoteSetter,Quote}}>
      {children}
    </GlobalData.Provider>
  );
}

export default Context;
