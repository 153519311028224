import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import BarChart from '../components/BarChat';
import { saveAs } from 'file-saver';
import { useNavigate } from 'react-router-dom';
import DownloadIcon from '@mui/icons-material/Download';
import { Link } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import logo from '../assets/APPLOGO.png';
import logo2 from '../assets/logo.png';
import { ToastContainer, toast } from 'react-toastify';
import { Loading } from '../components/Loading';
import { GlobalData } from '../Context';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import {
  PDFDownloadLink,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  BlobProvider,
  Image,
  pdf,
  Font,
} from '@react-pdf/renderer';
import '../App.css';
import '../PdfPage.css';
// import { resolve } from 'mathjs';

const PdfPage = () => {
  const [extractedText, setExtractedText] = useState(null);
  const [extractedTextWOF, setExtractedTextWOF] = useState(null);
  const [ExtraData, setExtraData] = useState();
  const { images, imagesWOF, Quote } = useContext(GlobalData);
  const [isLoading, setIsLoading] = useState(true);
  const [PdfRender, setPdfRender] = useState(false);
  const { state } = useLocation();
  const [error, setError] = useState(false);
  let { DataState } = useParams();

  console.log(images);

  const navigate = useNavigate();
  useEffect(() => {
    document.body.classList.add('RemoveScroll');
    if (state.file) {
      fetchandProcessData();
    } else if (state.ManualData) {
      setExtractedText(state.ManualData.Data_with_formulas);
      setExtractedTextWOF(state.ManualData.Data_without_formulas);
      setExtraData(state.ManualData.Extra_Data);
    }
  }, [state]);

  function replaceText(inputText) {
    // Define a regular expression pattern to match the format
    const pattern = /\$([\d,]+)\s\(\$\d+\.\d+\)/g;

    // Use the replace method to replace the matched text with the desired format
    const replacedText = inputText.replace(pattern, '$1');

    return replacedText;
  }

  function formatUSNumber(number) {
    // Convert the number to a string and split it into integer and decimal parts
    const parts = number.toString().split('.');
    const integerPart = parts[0];
    const decimalPart = parts.length > 1 ? `.${parts[1]}` : '';

    // Use regular expressions to add commas as thousands separators to the integer part
    const formattedIntegerPart = integerPart.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ','
    );

    // Combine the integer and decimal parts with the formatted integer part
    const formattedNumber = formattedIntegerPart + decimalPart;

    return formattedNumber;
  }

  function getCurrentDateDDMMYYYY() {
    var currentDate = new Date();
    var day = currentDate.getDate();
    var month = currentDate.getMonth() + 1; // Note that months are zero-indexed, so we add 1
    var year = currentDate.getFullYear();

    // Ensure day and month have two digits (e.g., 01, 02, 03)
    if (day < 10) {
      day = '0' + day;
    }
    if (month < 10) {
      month = '0' + month;
    }

    var formattedDate = month + '/' + day + '/' + year;

    return formattedDate;
  }

  const fetchandProcessData = async () => {
    const formData = new FormData();
    formData.append('file', state.file);

    try {
      const response = await axios.post(
        'https://quoter.capitalagfinance.com/BackEnd/extract',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      if (
        response.data.Data_with_formulas ||
        response.data.Data_without_formulas
      ) {
        setExtractedText(response.data.Data_with_formulas);
        setExtractedTextWOF(response.data.Data_without_formulas);
        setExtraData(response.data.Extra_Data);
      } else {
        setError(true);
      }
    } catch (error) {
      console.error(error);
      navigate('/');
      toast.error('Something went wrong');
    }
  };

  useEffect(() => {
    if (error == true) {
      toast.error(
        'An error has occurred during processing. Possibly due to an issue with the uploaded PDF file Format.'
      );
      navigate('/');
    }
  }, [error == true]);

  const styles = StyleSheet.create({
    page: {
      paddingBottom: 4,
      // Set A4 size
    },
    TopSec: {
      flexDirection: 'row',
      backgroundColor: 'white',
      marginTop: 8,
      display: 'flex',
      alignItems: 'center',
      marginBottom: 5,
    },
    image: {
      height: 90,
      width: 90,
      marginRight: 9,
    },
    LargeHeading: {
      fontSize: 12,
      marginTop: 4,
    },
    smallHeading: {
      fontSize: 12,
      color: '#222222',
      marginTop: 4,
    },
    heading2: {
      fontSize: 11,
      textAlign: 'left',
    },
    heading2_2: {
      fontSize: 13,
      fontWeight: 'bold',
      textAlign: 'left',
    },
    heading3: {
      textTransform: 'uppercase',
      fontSize: 10,
      color: '#222222',
      marginTop: 4,
      zIndex: 10,
    },
    smHeading: {
      fontSize: 9,
      textAlign: 'right',
    },
  });

  const MyDoc = () => (
    <Document>
      <Page size={[11 * 72, 8.5 * 72]}  style={styles.page}>
        <View style={{ paddingLeft: 30, paddingRight: 30 }}>
          {/* Top Section */}
          <View style={styles.TopSec}>
            {/* Left */}
            <View style={{ marginRight: 25 }}>
              <Image src={logo} style={styles.image} />
            </View>
            <View>
              <View>
                <Text style={styles.smallHeading}>
                  QUOTE PREPARED FOR
                </Text>
              </View>
              <View style={{ marginTop: 4 }}>
                <Text
                  style={{ fontSize: 20, textTransform: 'uppercase' }}
                >
                  {Quote}
                </Text>
              </View>
              <Text style={styles.smallHeading}>
                {getCurrentDateDDMMYYYY()}
              </Text>
            </View>
          </View>
          {/* Second section */}
          <View style={{ marginBottom: 5 }}>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                color: '#222222',
              }}
            >
              <View style={{ flex: 1 }}>
                <Text style={styles.heading2}>RISK ANALYSIS</Text>
              </View>
              <View style={{ flex: 1 }}>
                <Text style={[styles.heading2,{textAlign:'center'}]}>
                  PRODUCER ASSIGNMENT
                </Text>
              </View>
              <View style={{ flex: 1 }}>
                <Text style={[styles.heading2,{textAlign:"right"}]}>
                  CAPITAL AG ASSIGNMENT
                </Text>
              </View>
            </View>
            {/* Section 2.2 */}

            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                backgroundColor: '#0E2345',
                color: 'white',
                height: 30,
                marginTop: 2,
                paddingBottom: 1,
                alignItems: 'center',
                justifyContent: 'center',
                paddingHorizontal: 10,
                fontFamily: 'Helvetica-Bold',
                fontWeight: 1000,
              }}
            >
              <View style={{ flex: 1 }}>
                {ExtraData && (
                  <Text style={{ fontSize: 17, fontWeight: 'bold' }}>
                    {ExtraData.risk}
                  </Text>
                )}
              </View>
              <View style={{ flex: 1 }}>
                {ExtraData && (
                  <Text style={{ fontSize: 17, fontWeight: 'bold',textAlign:"center" }}>
                    {ExtraData.producer * 100}%
                  </Text>
                )}
              </View>
              <View style={{ flex: 1, marginLeft: 10 }}>
                {ExtraData && (
                  <Text style={{ fontSize: 17, fontWeight: 'bold',textAlign:"right" }}>
                    {ExtraData.RainShield * 100}%
                  </Text>
                )}
              </View>
            </View>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <View style={{ flex: 1 }}>
              {/* Title */}
              <View>
                <Text style={styles.heading3}>
                  Average Annual Net Without rainshield:
                </Text>
              </View>
            </View>
            <View style={{ flex: 1, textAlign: 'right' }}>
              {/* Title */}
              <View>
                <Text style={styles.heading3}>
                  Average annual net with rainshield and zero risk of
                  a payment:
                </Text>
              </View>
            </View>
          </View>

          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              backgroundColor: '#0E2345',
              color: 'white',
              height: 30,
              marginTop: 2,
              paddingBottom: 1,
              alignItems: 'center',
              paddingHorizontal: 10,
              justifyContent: 'space-between',
              marginTop: '4px',
              fontFamily: 'Helvetica-Bold',
              fontWeight: 1000,
            }}
          >
            <View>
              {ExtraData && (
                <Text
                  className="headinf"
                  style={{ fontSize: 17, fontWeight: 'bold' }}
                >
                  ${`${
                    DataState == 'manual'
                      ? formatUSNumber(ExtraData.avg_net_value)
                      : replaceText(ExtraData.avg_net_value[0])
                  }`}
                </Text>
              )}
            </View>
            <View>
              {ExtraData && (
                <Text style={{ fontSize: 17, fontWeight: 'bold' }}>
                  {' '}
                  $
                  {formatUSNumber(
                    `${
                      DataState == 'manual'
                        ? Math.round((
                            ExtraData.avg_net_value *
                            ExtraData.producer
                          ).toFixed(2))
                        : Math.round(
                            replaceText(
                              ExtraData.avg_net_value[0]
                            ).replace(/,/g, '') * ExtraData.producer
                          )
                    } `
                  )}
                </Text>
              )}
            </View>
          </View>
       
          {/* Third Sections Graphs */}
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',

            }}
          >
            {/* First Graph */}
            <View style={{ flex: 1 }}>
              {/* Title */}

              <View>{imagesWOF && <Image src={imagesWOF} />}</View>
            </View>
            {/* Second graph */}
            <View style={{ flex: 1, textAlign: 'left' }}>
              {/* Title */}

              <View>{images && <Image src={images} />}</View>
            </View>
          </View>

          {/* Disclaimer */}
          <Text
            style={{
              fontSize: '9px',
              color: '#222222',
            }}
          >
            DISCLAIMER: This is only an estimate, and is separate from
            any PRF policy, agent, or brokerage firm you may be
            working with. This offer is not an inducement to
            participate, and is not exclusive to your particular PRF
            agent or insurance broker.
          </Text>
          {/* Bottom */}
          <View
            style={{
              marginTop: '6px',

              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row',
            }}
          >
            {/* Left */}
            <View
              style={{
                justifyContent: 'flex-start',
                marginTop: 7,
              }}
            >
              <Image style={{ width: '180px' }} src={logo2} />
            </View>
            {/* Right */}
            <View
              style={{
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'right',
              }}
            >
              <View style={{ textAlign: 'right' }}>
                <Text style={styles.smHeading}>
                  capitalagfinance.com
                </Text>
              </View>
              <View>
                <Text style={styles.smHeading}>1-888.744.2530</Text>
              </View>
              <View>
                <Text style={styles.smHeading}>
                  info@GetRainShield.com
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );

  useEffect(() => {
    // Set the state to 'true' after 2 seconds
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1200);

    // Clean up the timer when the component unmounts
    return () => clearTimeout(timer);
  }, []);
  const generatePdfDocument = async (fileName) => {
    const blob = await pdf(<MyDoc />).toBlob();
    saveAs(blob, fileName);
  };

  console.log(images, '....');
  return (
    <div className="overflow-y-hidden">
      {(DataState == 'manual' && isLoading) ||
      !extractedText ||
      !extractedTextWOF ? (
        <div>
          <Loading />
        </div>
      ) : (
        <div className="overflow-y-hidden">
          <ToastContainer />

          {/* <BlobProvider document={<MyDoc />}>
            {({ blob, url, loading, error }) => {
              if (loading) {
                return 'Loading document...';
              }
              if (url) {
                return (
                  <embed
                    src={url}
                    type="application/pdf"
                    width="100%"
                    height="600px"
                  />
                );
              }
              return 'Error generating PDF';
            }}
          </BlobProvider> */}
          <div className=" flex justify-center items-center h-screen">
            <div className=" rounded-md flex mx-12 flex-col justify-center items-center shadow-lg p-16">
              <div className="flex lg:flex-row items-center mb-4 h-15 justify-center sm:flex-col md:flex-row xs:flex-col text-center flex-col ">
                <CheckCircleIcon className="text-[#0E2345] mr-1" />
                <p className="xl:text-center text-[#0E2345] lg:text-center md:text-center sm:text-center  lg:text-lg text-sm sm:text-xs font-bold">
                  Your Data has been Processed Successfully!!
                </p>
              </div>

              {/* <div className="py-4">
                <PDFDownloadLink
                  document={<MyDoc />}
                  fileName={`${Quote}-report.pdf`}
                >
                  {({ loading, error, blob, url }) =>    loading ? "Report loading..." : "Report ready to download"
                  }
                </PDFDownloadLink>
              </div> */}

              {images && imagesWOF ? (
                <div className="flex flex-col justify-center items-center">
                  <div className="flex lg:flex-row mb-4 sm:flex-col md:flex-row xs:flex-col text-center flex-col  items-center ">
                    <CheckCircleIcon className="text-[#0E2345] mr-1" />
                    <h2 className="text-[#0E2345] lg:text-lg sm:text-sm xl:text-center lg:text-center md:text-center sm:text-center font-bold">
                      Pdf Generated Successfully!!
                    </h2>
                  </div>
                  <p className="mb-4 whitespace-nowrap">
                    You can download pdf now
                  </p>
                  <button
                    className="mb-4"
                    onClick={() =>
                      generatePdfDocument(`${Quote}-report.pdf`)
                    }
                  >
                    <div className="flex items-center px-6 py-2 bg-[#0E2345] rounded-full ">
                      <DownloadIcon className="text-white" />
                      <button className="font-semibold text-white pl-2 whitespace-nowrap">
                        Download Pdf
                      </button>
                    </div>
                  </button>
                  <p className="text-center">OR</p>
                </div>
              ) : (
                <div>
                  <h2 className="text-[#0E2345] text-lg font-bold">
                    Generating Pdf...
                  </h2>
                </div>
              )}

              <div className="py-3">
                <Link to="/">
                  <div className="flex m-auto items-center  px-6 py-2 bg-[#0E2345] rounded-full ">
                    <HomeIcon className="text-white" />
                    <h2 className="font-semibold text-white pl-2">
                      Back to Home
                    </h2>
                  </div>
                </Link>
              </div>
            </div>
          </div>

          <div>
            <div>
              {/* Chart 2 with formulas*/}
              {extractedText && (
                <BarChart ExtractData={extractedText} />
              )}
            </div>
            <div>
              {/* Chart 2 with formulas*/}
              {extractedText && (
                <BarChart ExtractDataWOF={extractedTextWOF} />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PdfPage;
