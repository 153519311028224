import React from 'react';
import '../PdfPage.css';
export const Loading = () => {
  return (
    <div className="bg-white fixed h-full w-full flex items-center justify-center flex-col">
      <div role="status">
        <div className="spinner-container">
          <div className="loading-spinner"></div>
        </div>
      </div>
      <span>Processing...</span>
    </div>
  );
};
