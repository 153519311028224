import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './Pages/Home';
import PdfPage from './Pages/PdfPage';
import { Loading } from './components/Loading.js';
import { createContext } from 'react';
import Context from './Context';

const App = () => {
  return (
    <>
    <Context>
      <BrowserRouter>
        <Routes>
            <Route exact path='/' element={<Home />} />
            <Route path="/pdfpage/:DataState" element={<PdfPage />} />
            <Route path="/loading" element={<Loading />} />
        </Routes>
      </BrowserRouter>
      </Context>
    </>
  );
};

export default App;
